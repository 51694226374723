<template>
  <v-dialog v-model="modal" max-width="1000px" @click:outside="closeModal">
    <template v-slot:activator="{ on: dialog }">
      <div>
        <v-btn v-on="dialog" x-small plain block :disabled="disabled">
          <v-icon> mdi-download </v-icon>
          CSV
        </v-btn>
      </div>
    </template>

    <div v-if="profiles.length">
      <v-card class="d-flex flex-column">
        <v-card-title>
          <span class="card-title">
            Télécharger pour
            {{
              profiles.length > 1
                ? 'tous les PDLS'
                : `le PDL ${profiles[0].prm}`
            }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-radio-group v-model="selectedPhysicalMagnitude" :column="false">
              <template v-slot:label>
                <div class="label"><strong>Puissances</strong></div>
              </template>
              <v-radio
                v-for="(physicalMagnitude, i) in physicalMagnitudes"
                :key="'physicalMagnitude' + i"
                class="pe-5"
                :label="physicalMagnitude.label"
                :value="physicalMagnitude.value"
              />
            </v-radio-group>

            <v-radio-group v-model="selectedPace" :column="false">
              <template v-slot:label>
                <div class="label"><strong>Pas de relevé</strong></div>
              </template>
              <v-radio
                v-for="(measurementPace, i) in measurementPaces"
                :key="'measurementPace' + i"
                class="pe-5"
                :label="measurementPace.label"
                :value="measurementPace.value"
              />
            </v-radio-group>
          </v-container>
        </v-card-text>

        <v-card-actions class="d-flex align-items justify-end pa-8">
          <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
          </div>
          <v-spacer />
          <v-btn small color="primary" text @click="closeModal">
            Annuler
          </v-btn>
          <v-btn
            small
            color="primary"
            :loading="downloadProgress"
            @click.prevent="download"
          >
            Télécharger
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
  import { required } from 'vuelidate/lib/validators';
  import {
    isValidPaceWithApparentPhysicalMagnitude,
    isValidPaceWithSeveralDeliveryPoints,
  } from '../../validators/enedisValidators';

  import { mapState } from 'vuex';
  import {
    FetchableMeasurementPace,
    FetchablePhysicalMagnitude,
  } from '../../services/business-data/dto/measurement.interface';
  import enedisApiClient from '../../services/enedis-api/enedis-api.client';
  import {
    MATOMO_EVENTS,
    MatomoService,
  } from './../../services/MatomoApi.service';

  export default {
    name: 'MeasurementDownloadModal',
    components: {},
    props: {
      disabled: {
        type: Boolean,
        required: true,
      },
      profiles: {
        type: Array,
        required: true,
      },
      validation: {
        type: Function,
        required: true,
      },
    },
    data() {
      return {
        modal: false,
        downloadProgress: false,
        errorMessage: null,
        selectedPhysicalMagnitude: FetchablePhysicalMagnitude.ActivePower,
        selectedPace: FetchableMeasurementPace.Raw,
        physicalMagnitudes: [
          { label: 'Active', value: FetchablePhysicalMagnitude.ActivePower },
          {
            label: 'Réactive inductive',
            value: FetchablePhysicalMagnitude.InductiveReactivePower,
          },
          {
            label: 'Réactive capacitive',
            value: FetchablePhysicalMagnitude.CapacitiveReactivePower,
          },
          {
            label: 'Apparente',
            value: FetchablePhysicalMagnitude.ApparentPower,
          },
        ],
        measurementPaces: [
          { label: 'Brute', value: FetchableMeasurementPace.Raw },
          {
            label: '10 min',
            value: FetchableMeasurementPace.PT10M,
          },
          {
            label: '60 min',
            value: FetchableMeasurementPace.PT60M,
          },
        ],
      };
    },
    computed: {
      ...mapState('auth', ['user']),
    },
    validations: {
      selectedPhysicalMagnitude: {
        required,
        isValidPaceWithApparentPhysicalMagnitude,
        isValidPaceWithSeveralDeliveryPoints,
      },
      selectedPace: { required },
    },
    methods: {
      closeModal() {
        this.modal = false;
        this.errorMessage = null;
      },
      async download() {
        this.errorMessage = false;

        this.$v.$touch();
        this.validation();

        if (
          !this.$v.selectedPhysicalMagnitude
            .isValidPaceWithSeveralDeliveryPoints
        )
          this.errorMessage =
            'Un pas de relevé est obligatoire pour télécharger la courbe de puissance sur plusieurs prms';

        if (
          !this.$v.selectedPhysicalMagnitude
            .isValidPaceWithApparentPhysicalMagnitude
        )
          this.errorMessage =
            'Un pas de relevé est obligatoire pour télécharger la courbe de puissance apparente';

        if (!this.$v.$invalid) {
          try {
            MatomoService.trackEvent(
              MATOMO_EVENTS.downloadMeasurements,
              this.profiles.length
            );

            this.downloadProgress = true;

            const prms = this.profiles.map((profile) => profile.prm);
            const startDate = this.profiles[0].request.startDate;
            const endDate = this.profiles[0].request.endDate;

            const measurementPerimeter = {
              prms,
              startDate,
              endDate,
              physicalMagnitude: this.selectedPhysicalMagnitude,
              pace: this.selectedPace,
            };

            const parameters = {
              measurementPerimeter,
              userEmail: this.user.emails[0].value,
            };

            await enedisApiClient.exportsMeasurements(parameters);

            this.$emit('toggleSnackbarExport');
            this.closeModal();
          } catch (err) {
            let message;
            if (err.response && err.response.data && err.response.data.message)
              message = err.response.data.message;
            else message = err.message;

            this.errorMessage = ` Une erreur est survenue. Veuillez réessayer ou contacter le service IT: ${message}`;
          } finally {
            this.downloadProgress = false;
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/constants.scss';

  .label {
    width: 125px;
  }

  .card-title {
    font-size: 1.5em;
    color: var(--grey);
  }

  .button {
    padding: 2rem !important;
    border-radius: 0;
  }
</style>
